<template>
<article class="quiz-spring-res" id="js_pagetop">
	<h1 class="quiz-spring-res__header" id="js_observer">
		<img src="@/assets/img/quiz_spring/title.png" alt="春の体調管理に役に立つ知識をチェック！ 春のお天気健康検定">
		<p class="quiz-spring-res__header--logo"><span>presented by</span><img src="~@/assets/img/lp_spring2023/logo.png" alt="頭痛ーる"></p>
	</h1>

	<!-- 結果 -->
	<section class="quiz-spring-res__result">
		<h2 class="quiz-spring-res__result--title">採点結果</h2>
		<div :class="[
			'quiz-spring-res__result--rank'
		]">
			<img :src="require(`@/assets/img/quiz_spring/img_rank${computedRank.rank}.png`)" :alt="computedRank.text">
		</div>
		<div class="quiz-spring-res__result--inner">
			<p class="quiz-spring-res__result--percent"><span class="is-heading">正解率</span><em>{{ computedRank.percent }}</em><span>%</span></p>
			<p class="quiz-spring-res__result--discription">{{ computedRank.discription }}</p>
		</div>
	</section>
	<!-- /結果 -->

	<!-- 回答結果をみるボタン -->
	<a href="javascript:void(0);" @click.prevent="onScroll('js_result')"  class="quiz-spring-res__show-result">▼解答解説を見る</a>
	<!-- /回答結果をみるボタン -->

	<!-- プレゼント -->
	<section class="quiz-spring-res__present">
		<h2 class="quiz-spring-res__present--title"><span>限定版のカレンダーをプレゼント！</span></h2>
		<div class="quiz-spring-res__calendar">
			<p class="quiz-spring-res__calendar--img"><img src="@/assets/img/quiz_spring/calendar.jpg" alt="特別版のカレンダー" /></p>
		</div>
		<div class="quiz-spring-res__present--outro">
			<p>画像を長押しして<br>ダウンロードしてニャ</p>
		</div>
	</section>
	<!-- /プレゼント -->

	<!-- 回答解説 -->
	<section class="quiz-spring-res__explanation" id="js_result">
		<h2 class="quiz-spring-res__explanation--title">解答解説</h2>
		<ul class="quiz-spring-res__explanation--list">
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.1</h3>
				<p class="quiz-spring-res__explanation--question">春の不調の原因になる「春の５K」とは、乾燥、花粉、強風、黄砂、もう一つは？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>B</em><span>寒暖差</span></p>
				<p class="quiz-spring-res__explanation--discription">春の５Ｋとは、寒暖差(Kandansa)のようにアルファベットにしたときに頭文字が全てＫで始まる注意が必要なこと。4月は寒暖差が大きい時期で初夏の陽気になったすぐ後に、冬の寒さに逆戻りすることもあるため、体のリズムが崩れやすくなります。</p>
			</li>
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.2</h3>
				<p class="quiz-spring-res__explanation--question">花粉の飛ぶ量が増えるのは一日の中でいつ頃？正しい組み合わせは？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>C</em><span>昼前後と夕方</span></p>
				<p class="quiz-spring-res__explanation--discription">花粉は、気温が上がって飛びやすくなる昼前後と、気温が下がって上空の花粉が落ちてくる夕方頃(日没前後)に多く飛散します。気温が上がる前の午前10時頃までは花粉は少ない傾向があります。</p>
			</li>
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.3</h3>
				<p class="quiz-spring-res__explanation--question">きちんととめてないと洗濯物が飛ばされてしまう恐れがある風速はどれくらい？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>A</em><span>5メートル以上</span></p>
				<p class="quiz-spring-res__explanation--discription">軽い旗がはためくくらいの平均風速5メートルでも、洗濯物はしっかりとめておかないと、飛ばされてしまうことがあります。平均風速10メートル前後まで強まる時は洗濯物は室内干しがオススメ。</p>
			</li>
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.4</h3>
				<p class="quiz-spring-res__explanation--question">サクラが散り始めるサインは次のうち、どれ？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>A</em><span>花の中心が赤くなる</span></p>
				<p class="quiz-spring-res__explanation--discription">咲き始めから数日間は花の中心部は緑色ですが、もうすぐ散る頃の花は中心部が赤くなっていきます。赤が目立つようになると数日以内に散ってしまうので、お花見は早めに済ませると良さそうです。</p>
			</li>
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.5</h3>
				<p class="quiz-spring-res__explanation--question">春の空が霞んで見える原因の一つ「黄砂」は、どこから飛んで来る？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>C</em><span>ゴビ砂漠</span></p>
				<p class="quiz-spring-res__explanation--discription">黄砂とは、東アジアの砂漠域(ゴビ砂漠、タクラマカン砂漠など)や黄土地帯から強風により吹き上げられた多量の砂やちりが、上空の風によって運ばれ、大気中に浮遊する現象。日本では4月をピークに春に多く、空が黄色っぽくに煙ることがあります。</p>
			</li>
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.6</h3>
				<p class="quiz-spring-res__explanation--question">健康を保つのに最適な湿度は何％くらい？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>B</em><span>45～55％</span></p>
				<p class="quiz-spring-res__explanation--discription">40％を下回ると、喉などの粘膜のバリア機能が低下しやすくなり、お肌や眼なども乾きやすくなります。一方、60％を上回ると湿気で、汗が蒸発しずらくなり、熱がこもりやすくなったり、カビが発生しやすくなります。50％前後が健康の快適ゾーンです。</p>
			</li>
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.7</h3>
				<p class="quiz-spring-res__explanation--question">紫外線の強さは快晴の日を100％とした時に、くもりの日は何％の強さになる？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>B</em><span>60％</span></p>
				<p class="quiz-spring-res__explanation--discription">雲によって、紫外線が地上に届く強さは少し弱まりますが、曇りの時でも快晴の60%の強さで到達します。また、薄曇りの場合は、90％も届くため、しっかりと紫外線対策が必要です。</p>
			</li>
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.8</h3>
				<p class="quiz-spring-res__explanation--question">急激な気温の低下をもたらすのは、次のうちどれが通過した時？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>C</em><span>寒冷前線</span></p>
				<p class="quiz-spring-res__explanation--discription">寒冷前線は、通過前の東側には暖気、通過後の西側には寒気があって、その境目にできる前線です。寒冷前線の通過に伴い、強い雨が降るとともに、気温が急激に低下、気圧は急激に上昇するため、寒暖差や気圧変化で体調を崩しやすくなる場合があります。</p>
			</li>
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.9</h3>
				<p class="quiz-spring-res__explanation--question">光を浴びることで脳から分泌される通称「幸せのホルモン」は次のうちどれ？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>B</em><span>セロトニン</span></p>
				<p class="quiz-spring-res__explanation--discription">セロトニンは、心や自律神経のバランスを整え、頭をスッキリさせる効果のある神経伝達物質です。光を浴びる量が少ないとセロトニンの分泌量が減る場合があります。紫外線が弱い朝の太陽光を適度に浴びて、散歩をすると心身の健康維持や安眠にも効果的です。</p>
			</li>
			<li class="quiz-spring-res__explanation--item">
				<h3 class="quiz-spring-res__explanation--heading">Q.10</h3>
				<p class="quiz-spring-res__explanation--question">山を吹き降りる風の影響で気温が高くなることがあります。この現象を何という？</p>
				<span class="quiz-spring-res__explanation--heading">答え</span>
				<p class="quiz-spring-res__explanation--answer"><em>C</em><span>フェーン現象</span></p>
				<p class="quiz-spring-res__explanation--discription">フェーン現象とは、風上側から山を上った風が、山を越えたあと、高温で乾燥した風となり、風下側に吹き降ろす現象のこと。フェーン現象が発生すると、急激な気温上昇に加え、強い乾燥と風を伴う場合が多く、火災や体調不良の原因になることもあります。</p>
			</li>
		</ul>
	</section>
	<!-- /回答解説 -->

	<!-- アウトロ -->
	<nav class="quiz-spring-res__outro">
		<!-- アプリDLボタン -->
		<div class="about-rightnow__login">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="about-rightnow__login--button gtm-link_click_count01" data-gtm-event-cat-label="春のお天気健康検定_結果ページアプリDLボタンAndroid" data-gtm-event-act-label="trackingQuizSpring2023_appDownload_android">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
			<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="about-rightnow__login--button gtm-link_click_count01" data-gtm-event-cat-label="春のお天気健康検定_結果ページアプリDLボタンiOS" data-gtm-event-act-label="trackingQuizSpring2023_appDownload_iOS">便利なアプリをダウンロード<span>スゴ得ユーザーは使い放題！</span></a>
		</div>
		<!-- /アプリDLボタン -->

		<!-- お天気健康クイズTOPへボタン -->
		<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpspring2023' } })" class="quiz-spring-res__outro--quiztop">お天気健康クイズ<br>TOPへ</a>
		<!-- /お天気健康クイズTOPへボタン -->

		<!-- 頭痛ーるTOPへボタン -->
		<a href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="quiz-spring-res__outro--sitetop">頭痛ーるTOPへ</a>
		<!-- /頭痛ーるTOPへボタン -->
	</nav>
	<!-- /アウトロ -->

	<!-- 上部に戻るボタン -->
	<nav class="quiz-spring-res__pagetop" id="js_pagetop_btn">
		<a href="javascript:void(0);" @click.prevent="onScroll('js_pagetop')" class="quiz-spring-res__pagetop--link">
			<img src="~@/assets/img/pagetop_btn01.png" alt="ページTOPにもどる">
		</a>
	</nav>
	<!-- /上部に戻るボタン -->
</article>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'QuizSpringRes',
	components: {
	},
	data () {
		return {
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			backendUrl: cmnConst.BACKEND_URL,
			$html: null,
			pageTopObserver: null
		}
	},
	props: {
		result: {
			type: String,
			default: '0'
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid']),

		computedRank () {
			const result = Number(this.result)
			let objRank = {
				rank: 1,
				percent: result > 100 ? 0 : result,
				text: '健康天気ベイビー',
				discription: '体調不良にならないか心配じゃのう。まずは毎日更新のお天気健康クイズにトライして、春の健康に役立つ豆知識を身に付けるのじゃ！'
			}

			switch (true) {
				case result === 100:
					objRank.rank = 4
					objRank.percent = result
					objRank.text = '健康天気レジェンド'
					objRank.discription = '健康や天気の知識をマスターしているようじゃ！今後も頭痛ーるアプリを毎日使うと、ワシと同じ気象予報士になれるかもしれないぞ！'
					break
				case result >= 80 && result <= 99:
					objRank.rank = 3
					objRank.percent = result
					objRank.text = '健康天気エキスパート'
					objRank.discription = 'ホウホウ。春の健康や天気のことをよく知っているようじゃのう！今後も頭痛ーると一緒に健康的な毎日を過ごしてほしいんじゃ。'
					break
				case result >= 50 && result <= 79:
					objRank.rank = 2
					objRank.percent = result
					objRank.text = '健康天気ビギナー'
					objRank.discription = '春を健康的に乗り切るには少し知識が足りないようじゃのう。頭痛ーるアプリを毎日開いて、体調管理に気を付けるんじゃぞ！'
					break
			}

			return objRank
		}
	},
	mounted () {
		// 横幅に合わせてフォントサイズや余白をレスポンシブに可変する対応
		const propWidth = document.body.offsetWidth
		const style = propWidth <= 640 ? 'font-size: 16px; font-size: 4.267vw;' : 'font-size: 27px;'
		this.$html = document.getElementsByTagName('html')[0]
		this.$html.setAttribute('style', style)

		// ページTOPに戻るボタン表示の処理
		const $target = document.getElementById('js_observer')
		const $pagetopBtn = document.getElementById('js_pagetop_btn')
		this.pageTopObserver = new IntersectionObserver(entries => {
			const entry = entries[0]
			if (entry.isIntersecting) {
				$pagetopBtn.classList.remove('is-show')
			} else {
				$pagetopBtn.classList.add('is-show')
			}
		})
		this.pageTopObserver.observe($target)
	},
	beforeDestroy () {
		this.$html.removeAttribute('style')
		this.pageTopObserver.disconnect()
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

$quiz-primary-color: #43C2A0;
$quiz-secondary-color: #FF5A7C;
$quiz-background-color: #FFF8E8;
$quiz-heading-color: #431403;
$quiz-top-button-border: #F34267;

$spacing-3: 0.187rem;
$spacing-12: 0.75rem;
$spacing-13: 0.812rem;
$spacing-22: 1.375rem;
$spacing-23: 1.437rem;
$spacing-24: 1.5rem;
$spacing-46: 2.875rem;
$spacing-54_5: 3.406rem;
$spacing-102: 6.375rem;

$font-size-22: 1.375rem;
$font-size-26: 1.625rem;
$font-size-28: 1.75rem;
$font-size-40: 2.5rem;

$line-height-1_2: 1.2;
$line-height-1_375: 1.375;

.quiz-spring-res {
	font-family: $lp-family-primary;
	max-width: 640px;
	margin: 0 auto;
	padding: 0 $spacing-14;
	background-color: $quiz-background-color;
	color: $text-primary;
	font-size: $font-size-16;
	line-height: $line-height-1_375;

	ul, li { list-style: none; }

	em { font-style: normal; }

	img {
		width: 100%;
		display: block;
	}

	/* ヘッダー */
	&__header {
		margin: $spacing-10 auto 0;
		box-sizing: border-box;

		&--logo {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin: $spacing-2 auto 0;
			font-size: $font-size-10;

			& > img {
				width: 25.9366%;
				margin-left: $spacing-4;
			}
		}
	}

	/* 結果 */
	&__result {
		position: relative;
		margin: $spacing-30 auto 0;
		padding: $spacing-22 0 $spacing-20;
		border: $spacing-2 solid $quiz-secondary-color;
		border-radius: $spacing-10;
		background-color: $background-primary;

		&--title {
			position: absolute;
			top: -$spacing-14;
			left: 50%;
			transform: translateX(-50%);
			width: 37.9009%;
			margin: 0;
			padding: $spacing-4 0;
			font-family: $lp-family-secondary;
			font-weight: normal;
			color: $text-secondary;
			text-align: center;
			background-color: $quiz-secondary-color;
			border-radius: $spacing-46;
		}

		&--rank > img {
			display: block;
			width: 100%;
		}

		&--inner { padding: 0 $spacing-8; }

		&--percent {
			margin: -$spacing-12 $spacing-10 0;
			padding: 0 0 $spacing-12;
			text-align: center;
			font-family: $lp-family-tertiary;
			color: $quiz-secondary-color;
			border-bottom: 1px dashed $quiz-secondary-color;

			& > em {
				display: inline-block;
				width: 25.0815%;
				font-size: $font-size-40;
			}

			& > span {
				font-size: $font-size-22;

				&.is-heading {
					font-size: $font-size-18;
					color: $quiz-heading-color;
					vertical-align: super;
				}
			}
		}

		&--discription {
			position: relative;
			margin: $spacing-16 0 0;
			padding: 0 $spacing-13 0 $spacing-102;

			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: -$spacing-12;
				display: block;
				width: 31.1927%;
				padding-top: 25.0765%;
				background: url(~@/assets/img/quiz_spring/hiroshi.png) no-repeat 0 0;
				background-size: 100% auto;
			}
		}
	}

	/* 解説を見る */
	&__show-result {
		display: block;
		width: 85.0145%;
		margin: $spacing-24 auto 0;
		padding: $spacing-10 0;
		font-family: $lp-family-secondary;
		font-size: $font-size-18;
		color: $text-secondary;
		text-align: center;
		text-decoration: none;
		background-color: $quiz-primary-color;
		border-radius: $spacing-6;
	}

	/* プレゼント */
	&__present {
		margin: $spacing-30 auto 0;

		&--title {
			position: relative;
			margin: 0;
			padding-top: 8.64554%;
			font-family: $lp-family-tertiary;
			color: $quiz-secondary-color;
			font-weight: normal;
			font-size: $font-size-18;
			text-align: center;
			background: url(~@/assets/img/quiz_spring/calendar_title_bg.png) no-repeat 0 0;
			background-size: 100% auto;

			& > span {
				position: absolute;
				display: block;
				width: 100%;
				top: calc(50% - #{$spacing-2});
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		&--outro {
			position: relative;
			width: 81.5562%;
			padding-top: 17.8675%;
			margin: $spacing-20 auto 0;
			background: url(~@/assets/img/quiz_spring/fukidashi_maro.png) no-repeat 0 0;
			background-size: 100% auto;

			& > p {
				position: absolute;
				top: 50%;
				left: $spacing-13;
				transform: translateY(-50%);
				margin: 0;
				font-size: $font-size-14;
			}
		}
	}

	/* カレンダー */
	&__calendar {
		margin: $spacing-20 $spacing-26 0;
		background: url(~@/assets/img/quiz_spring/calendar_bg.png) no-repeat 50% 50%;
		background-size: 100% auto;

		&--img {
			position: relative;
			width: 55.2543%;
			padding-top: 105.0848%;
			margin: 0 auto;
			overflow: hidden;

			& > img {
				display: block;
				position: absolute;
				-webkit-touch-callout: default;
				top: 50%;
				left: 50%;
				height: 100%;
				width: auto;
				transform: translate(-50%, -50%);
			}
		}
	}

	/* 解説 */
	&__explanation {
		$parent: &;

		margin: $spacing-40 auto 0;

		&--title {
			padding: $spacing-8 0 $spacing-6;
			margin: 0;
			font-family: $lp-family-secondary;
			font-size: $font-size-20;
			color: $text-secondary;
			text-align: center;
			font-weight: normal;
			background-color: $quiz-primary-color;
			border-radius: $spacing-10 $spacing-10 0 0;
		}

		&--list {
			padding: $spacing-20 $spacing-18 $spacing-22;
			margin: 0;
			background-color: $background-primary;
			border: solid $quiz-primary-color;
			border-width: 0 $spacing-2 $spacing-2 $spacing-2;
			border-radius: 0 0 $spacing-10 $spacing-10;
		}

		&--item {
			padding: 0 0 $spacing-24;
			border-bottom: 1px dashed $quiz-primary-color;

			&:last-of-type {
				padding: 0;
				border-bottom: none;
			}
		}

		&--item + &--item { margin: $spacing-24 0 0 0; }

		&--heading {
			margin: 0;
			font-family: $lp-family-tertiary;
			text-align: center;
		}

		h3#{$parent}--heading {
			font-size: $font-size-26;
			font-weight: normal;
			color: $quiz-primary-color;
		}

		span#{$parent}--heading {
			display: block;
			margin: $spacing-10 0 0;
			font-size: $font-size-20;
			color: $quiz-heading-color;
		}

		&--question { margin: $spacing-6 0 0; }

		&--answer {
			display: flex;
			align-items: center;
			width: 91.2053%;
			padding: $spacing-6 $spacing-12;
			margin: $spacing-4 auto 0;
			background-color: $background-primary;
			border: $spacing-2 solid $quiz-secondary-color;
			border-radius: $spacing-23;
			box-sizing: border-box;

			& > em,
			& > span { display: block; }

			& > em {
				width: 11.1112%;
				font-family: $lp-family-tertiary;
				font-size: $font-size-24;
				text-align: center;
				color: $quiz-secondary-color;
			}

			& > span {
				flex: 1;
				margin: 0 0 0 $spacing-8;
				padding: $spacing-2 0 $spacing-2 $spacing-20;
				font-family: $lp-family-secondary;
				border-left: 1px solid $quiz-secondary-color;
			}
		}

		&--discription {
			margin: $spacing-14 0 0;
			color: $quiz-secondary-color;
		}
	}

	/* アウトロ */
	&__outro {
		margin: $spacing-40 auto 0;

		.about-rightnow__login {
			padding: 0;

			& > a { width: 86.4554%; }
		}

		&--quiztop {
			display: block;
			width: 86.4554%;
			margin: $spacing-30 auto 0;
			padding: $spacing-10 0;
			font-family: $lp-family-secondary;
			text-align: center;
			text-decoration: none;
			color: $text-seconday;
			font-size: $font-size-20;
			line-height: $line-height-1_2;
			background-color: $quiz-secondary-color;
			border-radius: $spacing-6;
			box-shadow: 0 $spacing-3 $quiz-top-button-border;
		}

		&--sitetop {
			font-family: $lp-family-secondary;
			display: block;
			width: 66.2825%;
			margin: $spacing-30 auto 0;
			padding: $spacing-10 0;
			border-radius: $spacing-26;
			font-weight: normal;
			text-align: center;
			text-decoration: none;
			box-sizing: border-box;
			color: $text-seconday;
			font-size: $font-size-24;
			background-color: $primary;
		}
	}

	/* ページTOPに戻るボタン */
	&__pagetop {
		position: sticky;
		max-width: 640px;
		width: 100%;
		margin: -$spacing-54_5 auto 0;
		bottom: 0;
		opacity: 0;
		transition: all .3s ease-in;
		pointer-events: none;

		&.is-show { opacity: 1; }

		&--link {
			display: block;
			width: 14.1211%;
			margin-left: auto;
			pointer-events: auto;

			& > img { display: block; }
		}
	}
}
</style>
